import Header from "../header/Header";
import Footer from "../../component/footer/Footer";
import "./gallery.css";
import { useEffect, useState } from "react";
import React from "react";
import { background, havc, food, marblePolishing } from "../../utils/clientimg";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}
const MarbleGallery = () => {
  const backgroundimg = background();
  const havcimg = havc();
  const marblePolishingimg = marblePolishing();

  const [slideview, setslideview] = useState(5);
  const [slidecount, setslidecount] = useState(5);
  const [center, setCenter] = useState(false);

  useEffect(() => {
    // Function to update slideview and slidecount based on window.innerWidth
    const updateSlideValues = () => {
      if (window.innerWidth <= 900) {
        setslideview(1);
        setslidecount(1);
        setCenter(true);
      } else {
        setslideview(3);
        setslidecount(3);
        setCenter(false);
      }
    };

    // Initial update
    updateSlideValues();

    // Event listener for window resize
    window.addEventListener("resize", updateSlideValues);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlideValues);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: slideview,
    slidesToScroll: slidecount,
    centerMode: center,

    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Header />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "150px",
          gap: "25px",
        }}
      >
        <h4 className="primary-heading">Gallery</h4>

        <div className="gallaery-img">
          <div>
            <Slider {...settings}>
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={marblePolishingimg[0]}
                />
              </div>
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={marblePolishingimg[1]}
                />
              </div>
              {/* <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={marblePolishingimg[2]}
                  />
                </div> */}
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={marblePolishingimg[3]}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="about-container">
        <div className="about-banner-container">
          <div className="about-bannerImage-container  background-img">
            {/* <img style={{backgroundColor:"red"}} draggable="false" src={foodimg[5]} alt="" /> */}
          </div>
          <div className="profile-text-section frontground">
            <h4 className="primary-heading">Marble Polishing</h4>
            <p className="primary-heading-white">
            We help you project a clean and inviting environment from the minute guests
and visitors arrive. Our solutions help keep your floors clean, shiny and always
looking their best our Expertise team helps to extend the life of your stone floor.
            </p>

            <h5 className="primary-heading-white">Advanced Marble Treatment: Crystalizing for Lasting Brilliance </h5>
            <p className="primary-heading-white">
            At our service, we go beyond traditional marble polishing. Our expert team specializes in crystalizing marble surfaces instead of just polishing them. This advanced technique not only brings out a brilliant shine but also adds a protective layer, 
            </p>

            <h5 className="primary-heading-white">Comprehensive Floor Care Solutions: </h5>
            <p className="primary-heading-white">
            Our commitment to presenting a spotless and inviting environment begins the moment guests and visitors step in. We offer comprehensive floor care solutions that keep your floors clean, shiny, and in optimal condition. With our expertise.
            </p>

            <h5 className="primary-heading-white"> Beyond Marble: Diverse Cleaning Services: </h5>
            <p className="primary-heading-white">
            In addition to our specialized marble treatments, we offer a range of cleaning services to meet diverse needs. .
            </p>

            <h5 className="primary-heading-white">Unmatched Expertise for a Cleaner Tomorrow:</h5>
            <p className="primary-heading-white">
            Our dedicated team of experts stands ready to cater to all your cleaning and maintenance needs. With a focus on attention to detail and quality service, we are committed to enhancing the cleanliness and appeal of your spaces..
            </p>

           
          </div>
          <div
            data-aos="zoom-in"
            className="about-image-section foreground-img"
          >
            <img src={marblePolishingimg[4]} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default MarbleGallery;
