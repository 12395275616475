
import Header from "../header/Header";
import Footer from "../../component/footer/Footer";
import "./gallery.css";
import { useEffect, useState } from "react";
import React from "react";
import { background ,landscaping, food } from "../../utils/clientimg";
import Slider from "react-slick";
import GalleryC from "../../component/Gallery/GalleryC";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
  
        onClick={onClick}
      />
    );
  }
const LandscapeGallery=()=>{
    const backgroundimg = background();
    const landscapingimg = landscaping();
  
    const [slideview, setslideview] = useState(3);
    const [slidecount, setslidecount] = useState(3);
    const [center, setCenter] = useState(false);
  
    useEffect(() => {
      // Function to update slideview and slidecount based on window.innerWidth
      const updateSlideValues = () => {
        if (window.innerWidth <= 900) {
          setslideview(1);
          setslidecount(1);
          setCenter(true)
        } else {
          setslideview(3);
          setslidecount(3);
          setCenter(false)
        }
      };
  
      // Initial update
      updateSlideValues();
  
      // Event listener for window resize
      window.addEventListener("resize", updateSlideValues);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", updateSlideValues);
      };
    }, []);
  
  
  
    const settings = {
      dots: true,
      infinite: true,
  
      slidesToShow: slideview,
      slidesToScroll: slidecount,
      centerMode:center,
  
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
    };
  
  
   
  
  
    return (
      <>
        <Header />
  
      
        <div
          style={{
            display: "flex",
            flexDirection:"column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "150px",
            gap:"25px"
          }}
        >
        <h4 className="primary-heading">Gallery</h4>
  
          <div className="gallaery-img" >
            <div>
              <Slider {...settings}>
                
  
                <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={landscapingimg[1]}
                  />
                </div>
                <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={landscapingimg[2]}
                  />
                </div>
                <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={landscapingimg[3]}
                  />
                </div>
                <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={landscapingimg[4]}
                  />
                </div>
                {/* <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={landscapingimg[17]}
                  />
                </div> */}
              </Slider>
              {/* <GalleryC/> */}
            </div>
          </div>
  
  
        </div>
  
  
  
  
  
        <div className="about-container">
          <div className="about-banner-container">
            <div className="about-bannerImage-container  background-img">
              {/* <img style={{backgroundColor:"red"}} draggable="false" src={foodimg[5]} alt="" /> */}
            </div>
            <div className="profile-text-section frontground">
              <h4 className="primary-heading">Landscape Designing and Developing Maintenance</h4>
              <p className="primary-heading-white">
              Whether it's a residential garden or a commercial property, our customized approach ensures that every detail aligns with your unique taste, creating a landscape that is distinctly yours.
              </p>
  
              <h5 className="primary-heading-white">Innovative Landscape Designing:  </h5>
              <p className="primary-heading-white">
              Embark on a journey of transformative beauty with our landscape designing services. Our team of experts brings visionary concepts to life, creating innovative and aesthetically pleasing landscapes that harmonize with the natural surroundings.
              </p>
  
              <h5 className="primary-heading-white">Scheduled Maintenance </h5>
              <p className="primary-heading-white">
                Regular schedules ensure consistent cleaning routines, preventing
                the accumulation of dust, dirt, and germs in various spaces.
              </p>
  
              <h5 className="primary-heading-white">Sustainable Landscape Development: </h5>
              <p className="primary-heading-white">
              Experience the fusion of environmental consciousness and design excellence with our sustainable landscape development services. We go beyond creating visually stunning landscapes by incorporating eco-friendly practices. 
              </p>
  
              <h5 className="primary-heading-white">
              Comprehensive Landscape Maintenance:
              </h5>
              <p className="primary-heading-white">
              Preserve the allure of your landscapes with our comprehensive maintenance services. We understand that a well-maintained landscape is a testament to its timeless beauty.
              </p>
  
              <h5 className="primary-heading-white">Personalized Landscape Solutions:</h5>
              <p className="primary-heading-white">
              Your outdoor space is a reflection of your individuality, and our personalized landscape solutions capture that essence. We work closely with you to understand your preferences, lifestyle, and vision.
              </p>
  
            
            </div>
            <div
              data-aos="zoom-in"
              className="about-image-section foreground-img"
            >
              <img src={landscapingimg[0]} alt="" />
            </div>
          </div>
        </div>
        <Footer/>
  
      </>
    );
}
export default LandscapeGallery;