import "./Careers.css";

import Careerscom from "../../component/Careers/Careerscom";
import Header from "../header/Header";
import Footer from "../../component/footer/Footer";

const Careers = () => {
  const clickOpenning = () => {};
  return (
    <>
      <Header />
      <div style={{ marginTop: "100px" ,}}>
        <div className="career-head">
          <div className="careers-padding">
            <div className="career-h-title">
              <p className="textweight"> Find the career of your dreams</p>
              <h2>Lets Work Together & Explore Opportunities</h2>
              <p className="textweight">
                A workplace that empowers you to rise! Start your story with us
              </p>
              {/* <a
                onClick={clickOpenning}
                className="btn btn-primary px-4 py-2 rounded-pill"
              >
                View Opening
              </a> */}
            </div>
          </div>
        </div>

        <div className=""></div>
      </div>

      <div className="careers-opening-container">

        <div className="career-open-header">
          <h2>Join US</h2>
          <p className="textweight">current opening</p>
        </div>

        <div  className="career-border" >

        <div className="career-open-container">
             <Careerscom services={'Cleaner'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'Security'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'FIre safety officer'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'Electrician'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'Hvac'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'Pest'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'Marble specialist'} location={'Chennai,Tamil Nadu,India'}/>
             <Careerscom services={'Landscapinh specialist'} location={'Chennai,Tamil Nadu,India'}/>
        </div>

        </div>




      </div>
      <Footer />
    </>
  );
};

export default Careers;
