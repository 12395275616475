
import './Careerscom.css'
const Careerscom=({services,location})=>{

const clickOpenning=()=>{
    
}
    return(
        <>

        <div className='Careerscom-container'>
        
            <p className='text-left margin-zero' style={{fontWeight:"500",}}>Full time </p>
            <p className='text-left margin-zero'  style={{fontSize:"17px",color:"blue",lineHeight:"26px",}}>{services} </p>
            <p className='text-left margin-zero'>{location} </p>
        
           

        </div>

        </>
    )
}

export default Careerscom

