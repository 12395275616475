
import Header from "../header/Header";
const Blog=()=>{


    return (
        <>

        <Header/>

        <div style={{marginTop:"100px"}}>
           <h1>Blog</h1>
        </div>
        
        </>
    )
}

export default Blog;