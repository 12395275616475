import Header from "../header/Header";
import Footer from "../../component/footer/Footer";
import "./gallery.css";
import { useEffect, useState } from "react";
import React from "react";
import {
  background,
  havc,
  food,
  marblePolishing,
  carpetunpolishing,
} from "../../utils/clientimg";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}
const CarpetGallery = () => {
  const backgroundimg = background();
  const havcimg = havc();
  // const marblePolishingimg = marblePolishing();
  const carpetunpolishingimg = carpetunpolishing();

  const [slideview, setslideview] = useState(5);
  const [slidecount, setslidecount] = useState(5);
  const [center, setCenter] = useState(false);

  useEffect(() => {
    // Function to update slideview and slidecount based on window.innerWidth
    const updateSlideValues = () => {
      if (window.innerWidth <= 900) {
        setslideview(1);
        setslidecount(1);
        setCenter(true);
      } else {
        setslideview(3);
        setslidecount(3);
        setCenter(false);
      }
    };

    // Initial update
    updateSlideValues();

    // Event listener for window resize
    window.addEventListener("resize", updateSlideValues);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlideValues);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: slideview,
    slidesToScroll: slidecount,
    centerMode: center,

    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Header />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "150px",
          gap: "25px",
        }}
      >
        <h4 className="primary-heading">Gallery</h4>

        <div className="gallaery-img">
          <div>
            <Slider {...settings}>
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={carpetunpolishingimg[0]}
                />
              </div>
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={carpetunpolishingimg[3]}
                />
              </div>
              {/* <div>
                  <img
                    style={{ width: "95%", objectFit: "cover" }}
                    src={marblePolishingimg[2]}
                  />
                </div> */}
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={carpetunpolishingimg[2]}
                />
              </div>
              <div>
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={carpetunpolishingimg[4]}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="about-container">
        <div className="about-banner-container">
          <div className="about-bannerImage-container  background-img">
            {/* <img style={{backgroundColor:"red"}} draggable="false" src={foodimg[5]} alt="" /> */}
          </div>
          <div className="profile-text-section frontground">
            <h4 className="primary-heading">Carpet Unphoslstery Shampooing</h4>
            <p className="primary-heading-white">We have process of deep cleaning the
carpet by( Injection and extraction )
method. Each and every piles of the
carpet deep cleaned and freshed up.</p>
            <h5 className="primary-heading-white">Professional Carpet Upholstery Shampooing: </h5>
            <p className="primary-heading-white">
            Discover the transformative power of our professional carpet upholstery shampooing service. We specialize in revitalizing your living and working spaces by effectively removing dirt, stains, and allergens from your carpets and upholstery.
            </p>

            <h5 className="primary-heading-white">Deep Cleaning Excellence: </h5>
            <p className="primary-heading-white">
            Experience deep cleaning excellence with our carpet upholstery shampooing services. We go beyond surface-level cleaning, penetrating fibers to eliminate embedded dirt and grime.
            </p>

            <h5 className="primary-heading-white"> Specialized Care for Upholstered Furniture:  </h5>
            <p className="primary-heading-white">
            Our expertise extends to specialized care for upholstered furniture. Whether it's a beloved sofa, chair, or other upholstered items, we employ gentle yet effective shampooing methods to preserve the fabric's integrity..
            </p>

            <h5 className="primary-heading-white"> Customized Solutions for Lasting Freshness:</h5>
            <p className="primary-heading-white">
            We understand that every carpet and piece of upholstery is unique. That's why our services are tailored to meet your specific needs. Our team evaluates the type of fabric, level of soiling, and any specific concerns you may have to provide customized solutions..
            </p>

           
          </div>
          <div
            data-aos="zoom-in"
            className="about-image-section foreground-img"
          >
            <img src={carpetunpolishingimg[5]} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default CarpetGallery;
