import Cardc from "../../component/card/Cardc";
import "./Services.css";
import { useNavRef } from "../../context/navigation/navContext";

import { Parallax } from "react-parallax";
import { background, food,electric,cleaning,fire,havc,pest,housekeeping,security,threeD } from "../../utils/clientimg";
import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Footer from "../../component/footer/Footer";
import Header from "../header/Header";
import Whyus from "../home/whyus";
import { useNavigate } from "react-router-dom";
import Services from "./Services";
const ServicesMain = () => {
  const backgroundimg = background();
  const foodimg = food();
  const housekeepingimg = housekeeping();
  const securityimg = security();
  const electricimg = electric();
  const cleaningimg = cleaning();
  const fireimg = fire();
  const havcimg = havc();
  const pestimg = pest();
  const threeDimg = threeD();



  const {
    Homesection,
    Aboutsection,
    Profilesection,
    Servicessection,
    Teamsection,
    Contactsection,
  } = useNavRef();

  const navigation =useNavigate();

  const [slideview,setslideview]=useState(3)
  const [slidecount,setslidecount]=useState(3)
  useEffect(() => {
    // Function to update slideview and slidecount based on window.innerWidth
    const updateSlideValues = () => {
      if (window.innerWidth <= 900) {
        setslideview(1);
        setslidecount(1);
      } else {
        setslideview(4);
        setslidecount(4);
      }
    };
  
    // Initial update
    updateSlideValues();
  
    // Event listener for window resize
    window.addEventListener('resize', updateSlideValues);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateSlideValues);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
  
    slidesToShow: slideview,
    slidesToScroll:slidecount,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

  };


 
  return (

    <>
    <Header/>
    
    <div style={{marginTop:"100px"}}>

  

    <Services/>
 

    <Whyus/>
    <Footer/>

    </div>
    </>
  );
};



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style,   color:"black " }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color:"black"}}

      onClick={onClick}
    />
  );
}
export default ServicesMain;


